import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'moment/locale/pt-br';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ChevronRight, PersonAddOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateRangePicker from '~/components/DateRangePicker';
import getDateRange from '~/util/getDateRange';
import Header from '~/components/Header';
import ChartStoreOverview from '~/components/ChartStoreOverview';
import EvaluationNotification from '~/components/EvaluationNotification';
import OmniIcon from '~/assets/icons/omni_large.svg';
import TrophyIcon from '~/assets/icons/trofeu_pink.svg';
import api, { omniApi } from '~/services/api';
import getAggregatedOmniSales from '~/util/getAggregatedOmniSales';
import { formatNumberToHour, formatPrice } from '~/util/format';
import { shouldRemoveExternalProducts } from '~/util/functions';
import Snackbar from '~/util/SnackBar';
import { FABULA, FOXTON, MARIAFILO } from '../../util/brands';
import * as Style from './styles';

function CustomCircularProgress(props) {
    return (
        <Style.CircularProgressRoot>
            <Style.CircularBottom
                variant="static"
                size={64}
                thickness={5}
                {...props}
                value={100}
            />
            <Style.CircularTop
                variant="determinate"
                size={64}
                thickness={5}
                value={props.value}
            />
        </Style.CircularProgressRoot>
    );
}

function CircularProgressWithLabel(props) {
    const getFormattedTypographyValue = value => {
        if (Number.isNaN(Math.floor(value)) || Math.abs(value) === Infinity) {
            return '-';
        }
        return Math.floor(value);
    };
    const getFormattedProgressValue = value => {
        if (Number.isNaN(Math.floor(value)) || Math.abs(value) === Infinity) {
            return 0;
        }
        if (value > 100) {
            return 100;
        }
        return Math.floor(value);
    };

    return (
        <Box position="relative" display="inline-flex">
            <CustomCircularProgress
                value={getFormattedProgressValue(props.value)}
            />
            <Style.CircularProgressLabelBox>
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{
                        fontSize: '16px',
                        position: 'absolute',
                        top: '19px',
                    }}
                >{`${getFormattedTypographyValue(props.value)}%`}</Typography>
            </Style.CircularProgressLabelBox>
        </Box>
    );
}
function StoreOverview({ ...props }) {
    const [quotaValues, setQuotaValues] = useState(undefined);
    const [salesCard, setSalesCard] = useState(undefined);
    const [omniCard, setOmniCard] = useState(undefined);
    const [clientsCard, setClientsCard] = useState(undefined);
    const [rankingCard, setRankingCard] = useState(undefined);
    const [chartSalesData, setChartSalesData] = useState([]);
    const [chartOmniData, setChartOmniData] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const history = useHistory();
    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate, period } = useSelector(
        state => state.status
    );
    const filialCode = userProfile.users_branches[0].codigo_filial;

    const formattedFilialCode =
        filialCode.length === 3 ? `000${filialCode}` : filialCode;

    const checkIfCanMakeRequests = useCallback(() => {
        return initialDate && finalDate;
    }, [finalDate, initialDate]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            const getNewClients = async () => {
                setClientsCard(currState => ({
                    ...currState,
                    loaded: false,
                }));

                const newClientsResponse = await api.get(
                    `/store-manager/new-clients`,
                    {
                        params: {
                            dateInit: moment(initialDate).format('YYYY-MM-DD'),
                            dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                            filialCode:
                                userProfile.users_branches[0].codigo_filial
                                    .length === 3
                                    ? `000${userProfile.users_branches[0].codigo_filial}`
                                    : userProfile.users_branches[0]
                                          .codigo_filial,
                        },
                    }
                );
                setClientsCard({
                    mainText: `+${newClientsResponse.data?.newClients
                        ?.client_number || 0}`,
                    secondaryText: `${newClientsResponse.data?.newClientsOnMonth?.client_number} no Mês`,
                    loaded: true,
                });
            };

            const getRankingStores = async () => {
                setRankingCard(currState => ({
                    ...currState,
                    loaded: false,
                }));

                const newRankingStores = await api.get(
                    `/store-manager/ranking-stores`,
                    {
                        params: {
                            dateInit: moment(initialDate).format('YYYY-MM-DD'),
                            dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                            brandId: userProfile.marcaFilial,
                            branchCode:
                                userProfile.users_branches[0].codigo_filial
                                    .length === 3
                                    ? `000${userProfile.users_branches[0].codigo_filial}`
                                    : userProfile.users_branches[0]
                                          .codigo_filial,
                            removeExternalProducts: shouldRemoveExternalProducts(
                                userProfile.marcaFilial
                            ),
                        },
                    }
                );

                setRankingCard({
                    mainText: `${newRankingStores?.data?.rankingPosition}º`,
                    secondaryText: `de ${newRankingStores.data.totalBranchesNumber} Lojas`,
                    loaded: true,
                });
            };

            const getAggregateSales = async () => {
                setSalesCard(currState => ({
                    ...currState,
                    loaded: false,
                }));

                // não remover, condição para mostrar os valores corretos antes da implementação da lógica de vendas externas da off premium.
                const beforeExternalOffPremiumDateLimit = new Date(
                    '2024-09-27'
                );

                const params = {
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    filialCode:
                        userProfile.users_branches[0].codigo_filial.length === 3
                            ? `000${userProfile.users_branches[0].codigo_filial}`
                            : userProfile.users_branches[0].codigo_filial,
                    filialBrandId: userProfile.marcaFilial,
                    removeExternalProducts: shouldRemoveExternalProducts(
                        userProfile.marcaFilial
                    ),
                };

                if (
                    moment(initialDate).format('YYYY-MM-DD') <=
                    moment(beforeExternalOffPremiumDateLimit).format(
                        'YYYY-MM-DD'
                    )
                ) {
                    delete params.removeExternalProducts;
                    params.offExternal = 'exclude';
                }

                const salesResponse = await api.get(
                    '/store/aggregated-orders',
                    {
                        params,
                    }
                );
                const newSalesChartData =
                    period === 'day'
                        ? Object.values(
                              salesResponse.data.data.aggregatedByHour
                          ).map(hourObj => ({
                              value: hourObj.value,
                              label: hourObj.prettyHour,
                          }))
                        : getDateRange(
                              initialDate,
                              finalDate,
                              'YYYY-MM-DD'
                          ).map(date => ({
                              value:
                                  salesResponse.data.data.aggregatedByDay[
                                      date.label
                                  ]?.value,
                              label: date.formattedLabel,
                          }));
                const accumulatedSalesChartData = newSalesChartData.map(
                    (currentSalesData, currentSalesDataIndex) => {
                        const accumulatedUntilCurrent = newSalesChartData.reduce(
                            (acc, curr, index) => {
                                if (index <= currentSalesDataIndex) {
                                    return acc + (curr.value || 0);
                                }
                                return acc;
                            },
                            0
                        );
                        return {
                            ...currentSalesData,
                            value: accumulatedUntilCurrent,
                        };
                    }
                );

                setChartSalesData(accumulatedSalesChartData);
                setSalesCard(currentSalesCardData => ({
                    ...currentSalesCardData,
                    mainText: formatPrice(
                        `${salesResponse.data.data.total.value +
                            salesResponse.data.data.returns.value}`
                    ),
                    value:
                        salesResponse.data.data.total.value +
                        salesResponse.data.data.returns.value,
                    loaded: true,
                }));
                setQuotaValues(currentQuotaValue => ({
                    ...currentQuotaValue,
                    sales:
                        salesResponse.data.data.total.value +
                        salesResponse.data.data.returns.value,
                }));
            };

            const getQuota = async () => {
                const quotaResponse = await api.get('/store/quota', {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        filialCode:
                            userProfile.users_branches[0].codigo_filial
                                .length === 3
                                ? `000${userProfile.users_branches[0].codigo_filial}`
                                : userProfile.users_branches[0].codigo_filial,
                    },
                });
                const newSecondaryText =
                    period === 'month'
                        ? `${
                              quotaResponse?.data?.periodQuotas?.find(
                                  periodObj => periodObj.period === 'month'
                              )?.quota
                          }`
                        : `${quotaResponse?.data?.totalQuota}`;

                setSalesCard(currentSalesCardData => ({
                    ...currentSalesCardData,
                    secondaryText: formatPrice(newSecondaryText),
                }));
                setQuotaValues(currentQuotaValue => ({
                    ...currentQuotaValue,
                    quota:
                        period === 'month'
                            ? quotaResponse.data.periodQuotas?.find(
                                  periodObj => periodObj.period === 'month'
                              )?.quota
                            : quotaResponse.data.totalQuota,
                }));
            };

            const getOmniSales = async () => {
                try {
                    const omniSales = await omniApi.get(
                        `/storeData/maisVendas?startDate=${initialDate.format(
                            'YYYY-MM-DD'
                        )}&endDate=${finalDate.format('YYYY-MM-DD')}&filial=${
                            userProfile.nomeFilial
                        }`,
                        {
                            headers: {
                                Authorization: `Ra0YaHTblw7MkUbcCpSKklfCA15ew74S`,
                            },
                        }
                    );

                    const aggregatedOmniSales = getAggregatedOmniSales(
                        initialDate.format('YYYY-MM-DD'),
                        finalDate.format('YYYY-MM-DD'),
                        omniSales?.data
                    );

                    const newOmniChartData =
                        period === 'day'
                            ? Object.values(
                                  aggregatedOmniSales.data.aggregatedByHour
                              ).map(hourObj => ({
                                  value: hourObj.value,
                                  label: hourObj.prettyHour,
                              }))
                            : getDateRange(
                                  initialDate,
                                  finalDate,
                                  'YYYY-MM-DD'
                              ).map(date => ({
                                  value:
                                      aggregatedOmniSales.data.aggregatedByDay[
                                          date.label
                                      ]?.value,
                                  label: date.formattedLabel,
                              }));
                    const accumulatedOmniChartData = newOmniChartData.map(
                        (currentSalesData, currentSalesDataIndex) => {
                            const accumulatedUntilCurrent = newOmniChartData.reduce(
                                (acc, curr, index) => {
                                    if (index <= currentSalesDataIndex) {
                                        return acc + (curr.value || 0);
                                    }
                                    return acc;
                                },
                                0
                            );
                            return {
                                ...currentSalesData,
                                value: accumulatedUntilCurrent,
                            };
                        }
                    );
                    setChartOmniData(accumulatedOmniChartData);
                } catch (err) {
                    const notFoundError = err?.response?.data?.error?.includes(
                        'Não foi encontrado nenhum sellerId'
                    );
                    if (!notFoundError) {
                        Snackbar.error(
                            'Erro retornando a lista de pedidos Omni'
                        );
                    }
                }
            };

            const getOmniIndicators = async () => {
                setOmniCard(currState => ({
                    ...currState,
                    loaded: false,
                }));

                try {
                    const omniIndicatorsResponse = await omniApi.get(
                        `/storeData/maisVendas/consolided?startDate=${initialDate.format(
                            'YYYY-MM-DD'
                        )}&endDate=${finalDate.format(
                            'YYYY-MM-DD'
                        )}&filial=${userProfile.nomeFilial ||
                            userProfile.nomeFilialExtra}`,
                        {
                            headers: {
                                Authorization: `Ra0YaHTblw7MkUbcCpSKklfCA15ew74S`,
                            },
                        }
                    );
                    const newOmniIndicators =
                        omniIndicatorsResponse.data[0] ||
                        omniIndicatorsResponse.data ||
                        null;

                    if (newOmniIndicators) {
                        setOmniCard(currentOmniCardData => ({
                            ...currentOmniCardData,
                            mainText: {
                                omniTMA:
                                    isNaN(newOmniIndicators?.tma_horas) ||
                                    (!newOmniIndicators?.tma_horas &&
                                        !newOmniIndicators?.receitas)
                                        ? '-h'
                                        : formatNumberToHour(
                                              newOmniIndicators?.tma_horas
                                          ),
                                omniTC:
                                    !newOmniIndicators?.taxa_cancelamento &&
                                    !newOmniIndicators?.receitas
                                        ? '-%'
                                        : `${newOmniIndicators?.taxa_cancelamento}%`,
                            },
                            secondaryText: formatPrice(
                                `${newOmniIndicators?.receitas}`
                            ),
                            value: newOmniIndicators?.receitas,
                            loaded: true,
                        }));
                    }
                } catch (err) {
                    const notFoundError = err.response.status === 404;
                    if (!notFoundError) {
                        Snackbar.error('Erro retornando a indicadores Omni');
                        return;
                    }
                    setOmniCard({
                        mainText: {
                            omniTMA: '-h',
                            omniTC: '-%',
                        },
                        secondaryText: formatPrice(`0`),
                        value: 0,
                        loaded: true,
                    });
                }
            };

            const getQuotaVerification = async () => {
                if ([FOXTON, FABULA].includes(userProfile?.marcaFilial)) {
                    const quotaVerificationResponse = await api.get(
                        '/store/quota-verification',
                        {
                            params: {
                                startDate: moment(initialDate).format(
                                    'YYYY-MM-DD'
                                ),
                                endDate: moment(finalDate).format('YYYY-MM-DD'),
                                filialCode:
                                    userProfile.users_branches[0].codigo_filial
                                        .length === 3
                                        ? `000${userProfile.users_branches[0].codigo_filial}`
                                        : userProfile.users_branches[0]
                                              .codigo_filial,
                                filialBrandId: userProfile?.marcaFilial,
                            },
                        }
                    );

                    setShowNotification(
                        quotaVerificationResponse.data.shouldShowNotification
                    );
                }
            };

            getNewClients();
            getAggregateSales();
            getQuota();
            getOmniIndicators();
            getOmniSales();
            getRankingStores();
            getQuotaVerification();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIfCanMakeRequests, finalDate, getDateRange, userProfile]);

    return (
        <>
            <Header />

            <DateRangePicker hidden={!salesCard?.loaded} assessment />

            <Grid container style={{ padding: '16px', textAlign: 'center' }}>
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: '8px', marginBottom: '32px' }}
                >
                    <ChartStoreOverview
                        chartSalesData={chartSalesData}
                        chartOmniData={chartOmniData}
                    />
                </Grid>
                {showNotification && (
                    <>
                        {[FOXTON, FABULA, MARIAFILO].includes(
                            userProfile.marcaFilial
                        ) && (
                            <Style.ClickableCard
                                item
                                primary
                                secondary
                                xs={12}
                                onClick={() => {
                                    history.push(
                                        `/cadastroDeCotas/${formattedFilialCode}`
                                    );
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Grid item xs={2}>
                                        <InfoOutlinedIcon
                                            style={{ color: '#FBDD00' }}
                                        />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Typography
                                            align="left"
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            Você deve cadastrar as cotas da sua
                                            equipe de vendas
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                style={{ marginRight: '8px' }}
                                            >
                                                <ChevronRight
                                                    style={{ color: '#FFFFFF' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.ClickableCard>
                        )}
                    </>
                )}

                <EvaluationNotification />

                <Style.PrimaryGridComponent item xs={12}>
                    <Style.ClickableCard
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        onClick={() => {
                            history.push('/storeCota');
                        }}
                    >
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <CircularProgressWithLabel
                                value={
                                    (quotaValues?.sales / quotaValues?.quota) *
                                    100
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {salesCard?.loaded ? (
                                <>
                                    <Style.PrimaryTypography align="right">
                                        Vendas
                                    </Style.PrimaryTypography>
                                    <Style.SecondaryTypography align="right">
                                        {salesCard?.mainText}
                                    </Style.SecondaryTypography>
                                    <Style.PrimaryTypography
                                        primary
                                        align="right"
                                    >
                                        Cota: {salesCard?.secondaryText}
                                    </Style.PrimaryTypography>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                        <Grid item>
                            <ChevronRight style={{ color: 'white' }} />
                        </Grid>
                    </Style.ClickableCard>
                </Style.PrimaryGridComponent>
                <Style.PrimaryGridComponent item xs={12}>
                    <Style.ClickableCard
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        onClick={() => {
                            history.push('/storeOmni');
                        }}
                    >
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <img src={OmniIcon} alt="omni-icon" />
                        </Grid>
                        <Grid item xs={6}>
                            {omniCard?.loaded ? (
                                <>
                                    <Style.PrimaryTypography align="right">
                                        Omni
                                    </Style.PrimaryTypography>

                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            maxWidth: 'none',
                                        }}
                                    >
                                        <Style.SecondaryTypography
                                            primary
                                            align="right"
                                        >
                                            {omniCard?.mainText.omniTMA}
                                        </Style.SecondaryTypography>
                                        <Style.SecondaryTypography secondary>
                                            |
                                        </Style.SecondaryTypography>
                                        <Style.SecondaryTypography
                                            primary
                                            align="right"
                                        >
                                            {omniCard?.mainText.omniTC}
                                        </Style.SecondaryTypography>
                                    </Grid>
                                    <Style.PrimaryTypography
                                        primary
                                        align="right"
                                    >
                                        {omniCard?.secondaryText}
                                    </Style.PrimaryTypography>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                        <Grid item>
                            <ChevronRight style={{ color: 'white' }} />
                        </Grid>
                    </Style.ClickableCard>
                </Style.PrimaryGridComponent>
                <Style.PrimaryGridComponent item xs={12}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        style={{ height: '100%' }}
                    >
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <PersonAddOutlined
                                fontSize="large"
                                style={{ color: '#DCC6B7' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {clientsCard?.loaded ? (
                                <>
                                    <Typography
                                        align="right"
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '700',
                                        }}
                                    >
                                        Clientes Novos
                                    </Typography>
                                    <Style.SecondaryTypography align="right">
                                        {clientsCard?.mainText}
                                    </Style.SecondaryTypography>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                        <Style.AlignmentDiv item />
                    </Grid>
                </Style.PrimaryGridComponent>
                <Style.PrimaryGridComponent item xs={12}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        style={{ height: '100%' }}
                    >
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <img
                                src={TrophyIcon}
                                alt="trophy-icon"
                                style={{ height: '40px', width: '40px' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                align="right"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '700',
                                }}
                            >
                                Ranking da Rede
                            </Typography>
                            <Style.SecondaryTypography align="right">
                                {rankingCard?.mainText}
                            </Style.SecondaryTypography>
                            <Style.PrimaryTypography primary align="right">
                                {rankingCard?.secondaryText}
                            </Style.PrimaryTypography>
                        </Grid>
                        <Style.AlignmentDiv item />
                    </Grid>
                </Style.PrimaryGridComponent>
            </Grid>
        </>
    );
}

export default StoreOverview;
