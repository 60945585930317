import React from 'react';

import SalesPersonPagePanel from '~/components/SalesPersonPanelPiloto/SalesPersonPagePanel';
import OrderListPanel from '~/components/OrderListPanel';
import * as Style from './styles';

const DesktopLayout = ({
    balanceText,
    indicatorsText,
    panelLoaded,
    ordersLoaded,
    orders,
    brandFilter,
    setBrandFilter,
    aggregatedOrdersByBrand,
    chartData,
    dateRange,
    farmLatamTotal,
    offExternalTotal,
}) => {
    return (
        <>
            <Style.PageContainer container>
                <Style.MainPanelContainer item xs={12}>
                    <SalesPersonPagePanel
                        balanceText={balanceText}
                        indicatorsText={indicatorsText}
                        panelLoaded={panelLoaded}
                        aggregatedOrdersByBrand={aggregatedOrdersByBrand}
                        chartData={chartData}
                        dateRange={dateRange}
                        farmLatamTotal={farmLatamTotal}
                        offExternalTotal={offExternalTotal}
                    />
                </Style.MainPanelContainer>
                <Style.LateralPanelContainer item xs={12}>
                    <OrderListPanel
                        orders={orders}
                        ordersLoaded={ordersLoaded}
                        brandFilter={brandFilter}
                        setBrandFilter={setBrandFilter}
                    />
                </Style.LateralPanelContainer>
            </Style.PageContainer>
        </>
    );
};

export default DesktopLayout;
