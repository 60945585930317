import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import DateRangePicker from '~/components/DateRangePicker';
import Header from '~/components/Header';
import CustomHeader from '~/components/CustomHeader';
import NPSForm from '~/components/NPSForm';
import SalesTerms from '~/components/SalesTerms';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import {
    onlineChannels,
    physicalChannels,
} from '~/util/mapChannelsPhysicalAndOnline';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';
import { shouldRemoveExternalProducts } from '~/util/functions';
import { ANIMALE, OFFPREMIUM } from '~/util/brands';

import {
    formatPrice,
    formatWithCent,
    formatCurrecyStringToNumberString,
    formatDecimalToPercentage,
} from '~/util/format';
import getDateRange from '~/util/getDateRange';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';
import * as Style from './styles';
import { changeShouldShowNPS } from '../../store/modules/user/actions';

function CotaPiloto({ location }) {
    const dispatch = useDispatch();
    const [aggregatedCapturedOrders, setAggregatedCapturedOrders] = useState(0);
    const [capturedOrdersIndicators, setCapturedOrdersIndicators] = useState(
        {}
    );
    const [totalOrdersIndicators, setTotalOrdersIndicators] = useState({});

    const [indicatorsText, setIndicatorsText] = useState({});
    const [quota, setQuota] = useState({});
    const [salesPercentage, setSalesPercentage] = useState('');
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [salesNumber, setSalesNumber] = useState(0);
    const [balanceText, setBalanceText] = useState(0);
    const [panelLoaded, setPanelLoaded] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [brandFilter, setBrandFilter] = useState('myBrands');

    const userProfileFromStore = useSelector(state => state.user.profile);
    const [userProfile, setUserProfile] = useState(undefined);
    const { initialDate, finalDate, isFaturado, period } = useSelector(
        state => state.status
    );
    const dateRange = getDateRange(initialDate, finalDate, 'DD/MM/YYYY');
    const [aggregatedOrdersByPeriod, setAggregatedOrdersByPeriod] = useState(
        []
    );
    const [salesCodeFromParams, setSalesCodeFromParams] = useState('');

    // não remover, condição para mostrar os valores corretos antes da implementação da lógica de vendas externas da off premium.
    const beforeExternalOffPremiumDateLimit = new Date('2024-09-27');

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });

            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const salesCode = query.get('salesCode');
        setSalesCodeFromParams(salesCode);
        if (
            salesCode &&
            [6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18].includes(
                userProfileFromStore.id_perfil
            )
        ) {
            const salesCodeFromQueryParams = salesCode.toUpperCase();
            const getUserData = async () => {
                const response = await api.post(`session/user-data`, {
                    salesCode: salesCodeFromQueryParams,
                });
                setUserProfile(response.data.user);
            };
            getUserData();
        } else {
            setUserProfile(userProfileFromStore);
        }
        // eslint-disable-next-line react/destructuring-assignment
    }, [location.search, userProfileFromStore]);
    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const isFarm = userProfile?.marcaFilial === 2;
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;

        if (shouldAnswerNPS) {
            if (!(beforeDateLimit && isFarm)) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const newIndicatorsText = {
            totalValue: 0,
            totalAverageValue: 0,
            totalOrderQuantity: 0,
            totalProductQuantity: 0,
            totalAverageQuantity: 0,

            onlineTotalValue: 0,
            onlineAverageValue: 0,
            onlineAverageQuantity: 0,
            onlineOrderQuantity: 0,
            onlineProductQuantity: 0,

            physicalTotalValue: 0,
            physicalAverageValue: 0,
            physicalAverageQuantity: 0,
            physicalOrderQuantity: 0,
            physicalProductQuantity: 0,
        };
        const ordersIndicators = capturedOrdersIndicators;

        Object.entries(ordersIndicators).forEach(
            ([indicatorChannel, indicatorValue]) => {
                if (onlineChannels.includes(indicatorChannel)) {
                    newIndicatorsText.onlineTotalValue += indicatorValue.value;
                    newIndicatorsText.onlineOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.onlineProductQuantity +=
                        indicatorValue.productQuantity;
                }
                if (physicalChannels.includes(indicatorChannel)) {
                    newIndicatorsText.physicalTotalValue +=
                        indicatorValue.value;
                    newIndicatorsText.physicalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.physicalProductQuantity +=
                        indicatorValue.productQuantity;
                }
            }
        );

        newIndicatorsText.onlineAverageQuantity = (
            newIndicatorsText.onlineProductQuantity /
                newIndicatorsText.onlineOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.onlineAverageValue =
            newIndicatorsText.onlineTotalValue /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.physicalAverageQuantity = (
            newIndicatorsText.physicalProductQuantity /
                newIndicatorsText.physicalOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.physicalAverageValue =
            newIndicatorsText.physicalTotalValue /
                newIndicatorsText.physicalOrderQuantity || 0;
        if (totalOrdersIndicators) {
            if (
                userProfile &&
                Object.keys(totalOrdersIndicators)?.length !== 0
            ) {
                const totalIndicators =
                    totalOrdersIndicators[userProfile.marcaFilial];

                if (totalIndicators) {
                    newIndicatorsText.totalAverageValue =
                        totalIndicators.value / totalIndicators.serviceQuantity;
                    newIndicatorsText.totalAverageQuantity = (
                        totalIndicators.productQuantity /
                        totalIndicators.serviceQuantity
                    ).toFixed(2);
                    newIndicatorsText.totalValue = totalIndicators.value;
                    newIndicatorsText.totalOrderQuantity =
                        totalIndicators.serviceQuantity;
                    newIndicatorsText.totalProductQuantity =
                        totalIndicators.productQuantity;
                }

                if (userProfile.marcaFilial === ANIMALE) {
                    const totalIndicatorsAllBrands =
                        totalOrdersIndicators[OFFPREMIUM];

                    if (totalIndicatorsAllBrands) {
                        newIndicatorsText.totalAverageValue =
                            (totalIndicatorsAllBrands.value +
                                newIndicatorsText.totalValue) /
                            (totalIndicatorsAllBrands.serviceQuantity +
                                newIndicatorsText.totalOrderQuantity);
                        newIndicatorsText.totalAverageQuantity = (
                            (totalIndicatorsAllBrands.productQuantity +
                                newIndicatorsText.totalProductQuantity) /
                            (totalIndicatorsAllBrands.serviceQuantity +
                                newIndicatorsText.totalOrderQuantity)
                        ).toFixed(2);
                        newIndicatorsText.totalValue +=
                            totalIndicatorsAllBrands.value;
                        newIndicatorsText.totalOrderQuantity +=
                            totalIndicatorsAllBrands.serviceQuantity;
                        newIndicatorsText.totalProductQuantity +=
                            totalIndicatorsAllBrands.productQuantity;
                    }
                }
            }
        }

        const canFormat = [
            'onlineTotalValue',
            'physicalTotalValue',
            'totalValue',
            'totalAverageValue',
            'physicalAverageValue',
            'onlineAverageValue',
        ];

        Object.keys(newIndicatorsText).forEach(key => {
            if (canFormat.includes(key)) {
                newIndicatorsText[key] = formatWithCent(newIndicatorsText[key]);
            }
        });

        setIndicatorsText(newIndicatorsText);
    }, [
        capturedOrdersIndicators,
        isFaturado,
        totalOrdersIndicators,
        userProfile,
    ]);

    useEffect(() => {
        if (aggregatedCapturedOrders && aggregatedCapturedOrders[0] === '-') {
            return setSalesPercentage('0%');
        }
        const salesValue = formatCurrecyStringToNumberString(
            aggregatedCapturedOrders
        );
        const quotaValue = formatCurrecyStringToNumberString(quota.totalQuota);
        const newSalesPercentage = formatDecimalToPercentage(
            Number(salesValue) / Number(quotaValue)
        );
        setSalesPercentage(newSalesPercentage);
    }, [isFaturado, aggregatedCapturedOrders, quota.totalQuota]);

    const getBrandsParamsForRequests = useCallback(() => {
        const allBrandsIds = Array.from(
            { length: 16 },
            (_, brandId) => brandId + 1
        );
        const myBrands = userProfileFromStore.marcaFilial;

        switch (brandFilter) {
            case 'allBrands':
                return [];
            case 'myBrands':
                return myBrands;
            case 'otherBrands':
                return allBrandsIds.filter(
                    allBrandsId => !myBrands.includes(allBrandsId)
                );
            default:
                return [];
        }
    }, [brandFilter, userProfileFromStore]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setPanelLoaded(false);

            const getSalespersonQuota = async () => {
                const response = await api.get(`salesperson/quota`, {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode:
                            userProfile.users_sales_code[0].codigo_vendedor,
                        brand: userProfileFromStore.marcaFilial,
                    },
                });
                const newSalespersonQuota = {
                    ...response.data,
                    totalQuotaAsNumber: response.data.totalQuota,
                    totalQuota: formatPrice(response.data.totalQuota),
                };
                setQuota(newSalespersonQuota);
            };
            const getAggregatedCapturedOrders = async () => {
                try {
                    const query = new URLSearchParams(location.search);
                    const salesCode = query.get('salesCode');

                    const params = {
                        fatured: 'false',
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        brands: getBrandsParamsForRequests(),
                        transaction: 'true',
                        removeExternalProducts: shouldRemoveExternalProducts(
                            userProfileFromStore.marcaFilial
                        ),
                    };

                    if (
                        moment(initialDate).format('YYYY-MM-DD') <=
                        moment(beforeExternalOffPremiumDateLimit).format(
                            'YYYY-MM-DD'
                        )
                    ) {
                        delete params.removeExternalProducts;
                        params.offExternal = 'exclude';
                    }

                    if ([2].includes(userProfileFromStore.marcaFilial)) {
                        params.farmLatam = 'exclude';
                    }

                    if (salesCode) {
                        params.filialCode =
                            userProfileFromStore.users_branches[0].codigo_filial;
                    }

                    const response = await api.get(
                        `/salesperson/aggregated-orders`,
                        {
                            params,
                        }
                    );

                    setChartData(
                        period === 'day'
                            ? Object.values(
                                  response.data.data.aggregatedByHour
                              ).map(hourObj => ({
                                  value: hourObj.value,
                                  label: hourObj.prettyHour,
                              }))
                            : getDateRange(
                                  initialDate,
                                  finalDate,
                                  'YYYY-MM-DD'
                              ).map(date => ({
                                  value:
                                      response.data.data.aggregatedByDay[
                                          date.label
                                      ]?.value,
                                  label: date.formattedLabel,
                              }))
                    );

                    setTotalOrdersIndicators(
                        response.data.data.aggregatedByBrand
                    );

                    setAggregatedCapturedOrders(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setBalanceText(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setSalesNumber(
                        Number(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        ) * 100
                    );
                    setCapturedOrdersIndicators(
                        response.data.data.aggregatedByChannel
                    );

                    setAggregatedOrdersByPeriod(response.data.periodSales);

                    if (response.data.msg) {
                        Snackbar.info(response.data.msg);
                    }
                } catch {
                    Snackbar.error(
                        'Erro retornando os indicadores de pedidos capturados'
                    );
                }
            };
            Promise.all([getAggregatedCapturedOrders(), getSalespersonQuota()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => setPanelLoaded(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        getBrandsParamsForRequests,
    ]);

    useEffect(() => {
        setOrders([]);
        if (checkIfCanMakeRequests()) {
            setOrdersLoaded(false);
            const getSalespersonOrders = async () => {
                try {
                    const query = new URLSearchParams(location.search);
                    const salesCode = query.get('salesCode');

                    const params = {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        orderType: isFaturado ? 'invoiced' : 'captured',
                        brands: getBrandsParamsForRequests(),
                        transaction: 'true',
                        removeExternalProducts: shouldRemoveExternalProducts(
                            userProfileFromStore.marcaFilial
                        ),
                    };

                    if (
                        moment(initialDate).format('YYYY-MM-DD') <=
                        moment(beforeExternalOffPremiumDateLimit).format(
                            'YYYY-MM-DD'
                        )
                    ) {
                        delete params.removeExternalProducts;
                        params.offExternal = 'exclude';
                    }

                    if ([2].includes(userProfileFromStore.marcaFilial)) {
                        params.farmLatam = 'exclude';
                    }

                    if (salesCode) {
                        params.filialCode =
                            userProfileFromStore.users_branches[0].codigo_filial;
                    }

                    const response = await api.get(`salesperson/orders`, {
                        params,
                    });

                    const formattedOrderData = await handleOrdersResponse(
                        response.data.data,
                        false
                    );

                    setOrders(formattedOrderData);
                    setOrdersLoaded(true);
                } catch {
                    Snackbar.error('Erro retornando a lista de pedidos');
                    setOrders([]);
                }
                setOrdersLoaded(true);
            };
            getSalespersonOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        brandFilter,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            {location.search.includes('salesCode') ? (
                <CustomHeader
                    title={
                        userProfile &&
                        `${userProfile?.nome
                            .split(' ')[0]
                            .toLowerCase()} (${salesCodeFromParams})`
                    }
                />
            ) : (
                <Header />
            )}

            <DateRangePicker hidden={!panelLoaded} assessment />

            {panelLoaded || (
                <Style.PanelLoadingContainer container spacing={1}>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Style.PanelLoadingContainer>
            )}
            <DesktopLayout
                balanceText={balanceText}
                indicatorsText={indicatorsText}
                panelLoaded={panelLoaded}
                aggregatedOrdersByPeriod={aggregatedOrdersByPeriod}
                chartData={chartData}
                dateRange={dateRange}
                salesNumber={salesNumber}
                quota={quota}
                salesPercentage={salesPercentage}
                orders={orders}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
                ordersLoaded={ordersLoaded}
            />
        </>
    );
}

export default CotaPiloto;
