import { styled } from '@mui/material/styles';
import { Dialog, Button, CardMedia, Typography } from '@mui/material';

export const DialogContainer = styled(Dialog)`
    display: flex;
    justify-content: ${props => (props.primary ? 'flex-start' : 'center')};

    @media screen and (max-width: 600px) {
        justify-content: center;
    }

    & .MuiDialog-paper {
        background-color: #dcc6b7;
        margin: 0;
        padding: 40px;
        box-sizing: border-box;
        width: 440px;
        min-height: ${props => (props.primary ? '100vh' : 'fit-content')};
        animation: Slide_left 300ms ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @media screen and (max-width: 600px) {
            width: ${props => (props.primary ? '100vw' : '90vw')};
            min-height: ${props => (props.primary ? '90vh' : 'fit-content')};
            padding: ${props => (props.primary ? '29px 24px 48px' : '40px')};
            border-radius: ${props => props.primary && '15px 15px 0 0'};
            margin: ${props => props.primary && '32px 0 0'};
            position: absolute;
            bottom: ${props => props.primary && '0'};
            animation: Slide_up 300ms ease;
        }

        @keyframes Slide_up {
            0% {
                transform: translateY(1000px);
            }
            100% {
                transform: translateY(0);
            }
        }

        @keyframes Slide_left {
            0% {
                transform: translateX(1000px);
            }
            100% {
                transform: translateX(0);
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            gap: 1rem;
            margin-bottom: 1rem;

            li {
                list-style-type: disc;
                font-family: inherit;
                font-size: 14px;
                color: black;

                @media screen and (min-width: 1800px) {
                    font-size: 16px;
                }
            }
        }
    }
`;

export const ModalImage = styled(CardMedia)`
    width: ${props => (props.primary ? '233px' : '150px')};
    align-self: left;
    margin: 50px 0 24px;
    @media screen and (max-width: 600px) {
        width: 112px;
    }
`;

export const CloseButtonContainer = styled(Button)`
    width: fit-content;
    align-self: end;
    font-size: 24px;
    color: black;
    padding: 0;

    & .MuiButtonBase-root {
        min-width: fit-content;
    }
`;

export const ModalTitle = styled(Typography)`
    font-family: inherit;
    color: black;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 1rem;
`;
