import moment from 'moment';
import { TREINADOR_ADORO_CODIGO } from './perfilId';

const getAdoroCodigoChallengePeriod = userProfile => {
    const minimumChallengeDate = `2024-11-01`;
    const maximumChallengeDate = `2024-12-31`;

    if (
        moment().isSameOrBefore(moment(maximumChallengeDate), 'day') &&
        moment().isSameOrAfter(moment(minimumChallengeDate), 'day') &&
        userProfile === TREINADOR_ADORO_CODIGO
    ) {
        return true;
    }
    return false;
};

export default getAdoroCodigoChallengePeriod;
