import {styled} from '@mui/material/styles';
import { Grid, Box, Typography } from '@mui/material';

export const LateralPanelContainer = styled(Grid)`
    height: fit-content;
    @media screen and (min-width: 960px){
        margin: 16px;
    }
`

export const DatePickerContainer = styled(Box)`
    width: 100%;
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 4;
    background-color: #212121;
`

export const MainTypography = styled(Typography)`
    font-family: inherit;
    font-size: 14px;
    color: black;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 0;

    @media screen and (min-width: 1800px) {
        font-size: 16px;
    }
`;

export const ModalTitle = styled(Typography)`
    font-size: 24px;
    font-weight: 400;
    color: black;
    line-height: 1.5rem;
    margin-left: 1rem;

    @media screen and (max-width: 960px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;