/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import ReactCardFlip from 'react-card-flip';
import { useLocation } from 'react-router';
import {
    CAIXA_SOMA,
    VENDEDOR_RESPONSAVEL,
    ESTOQUISTA_SOMA,
} from '~/util/perfilId';
import { FARM, ANIMALE, OFFPREMIUM } from '~/util/brands';
// import { CASA_FARM } from '~/util/branches';

import LoadingComponent from '~/components/SalesPersonPanelPiloto/LoadingComponent';
import SecondaryPanelCard from '~/components/PanelCards/SecondaryPanelCard';
import ChartPanelCard from '~/components/PanelCards/ChartPanelCard';
import SalesPersonCard from '~/components/PanelCards/SalesPersonCard';
import MainPanelCard from '~/components/PanelCards/MainPanelCards';

import * as Style from './styles';

function SalesPersonPagePanel({
    balanceText,
    indicatorsText,
    panelLoaded,
    aggregatedOrdersByBrand,
    aggregatedOrdersByPeriod,
    chartData,
    dateRange,
    salesNumber,
    quota,
    salesPercentage,
    hideSuperQuota,
    farmLatamTotal,
    offExternalTotal,
}) {
    const theme = useTheme();
    const user = useSelector(state => state.user.profile);
    const location = useLocation().pathname;

    const [activeStep, setActiveStep] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isCardSwipeDisabled, setIsSwipeCardDisabled] = useState(false);

    const farmStockWorker =
        user?.marcaFilial === FARM &&
        [CAIXA_SOMA, ESTOQUISTA_SOMA].includes(user?.id_perfil);

    const handleStepChange = step => {
        setActiveStep(step);
    };

    const storeProfile =
        [VENDEDOR_RESPONSAVEL, CAIXA_SOMA, ESTOQUISTA_SOMA].includes(
            user.id_perfil
        ) && location === '/vendedoraPiloto';

    const getMaxSteps = () => {
        let maxSteps = 4;
        if (storeProfile) maxSteps = 2;
        if (dateRange.length <= 1) maxSteps--;

        return maxSteps;
    };

    const handleFlipped = () => {
        setIsFlipped(!isFlipped);
    };

    const maxSteps = getMaxSteps();

    const GetFirstPanelCard = () => {
        const isFarmOrAnimaleSalespersonPage =
            [ANIMALE, FARM, OFFPREMIUM].includes(user.marcaFilial) &&
            !farmStockWorker &&
            location === '/vendedoraPiloto';

        if (isFarmOrAnimaleSalespersonPage) {
            return (
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <SalesPersonCard
                        balanceText={balanceText}
                        aggregatedOrdersByBrand={aggregatedOrdersByBrand}
                        handleFlipped={handleFlipped}
                        showIcon={dateRange.length > 1}
                        farmLatamTotal={farmLatamTotal}
                        offExternalTotal={offExternalTotal}
                    />
                    <>
                        {dateRange.length > 1 && (
                            <ChartPanelCard
                                chartData={chartData}
                                quota={quota}
                                setIsSwipeCardDisabled={setIsSwipeCardDisabled}
                                handleFlipped={handleFlipped}
                            />
                        )}
                    </>
                </ReactCardFlip>
            );
        }
        if (location === '/storeCota' || location === '/cotaPiloto') {
            return (
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <MainPanelCard
                        balanceText={indicatorsText.totalValue}
                        salesNumber={salesNumber}
                        quota={quota}
                        salesPercentage={salesPercentage}
                        aggregatedOrdersByPeriod={aggregatedOrdersByPeriod}
                        hideSuperQuota={hideSuperQuota}
                        showIcon={dateRange.length > 1}
                        handleFlipped={handleFlipped}
                    />
                    <>
                        {dateRange.length > 1 && (
                            <ChartPanelCard
                                chartData={chartData}
                                setIsSwipeCardDisabled={setIsSwipeCardDisabled}
                                handleFlipped={handleFlipped}
                            />
                        )}
                    </>
                </ReactCardFlip>
            );
        }
        return (
            <>
                {(dateRange.length > 1 || farmStockWorker) && (
                    <ChartPanelCard
                        balanceText={balanceText}
                        chartData={chartData}
                        setIsSwipeCardDisabled={setIsSwipeCardDisabled}
                        handleFlipped={handleFlipped}
                    />
                )}
            </>
        );
    };

    return (
        <>
            {panelLoaded ? (
                <>
                    <Style.Root>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            disabled={isCardSwipeDisabled}
                        >
                            {[
                                <GetFirstPanelCard />,

                                !storeProfile && (
                                    <SecondaryPanelCard
                                        cardTitle="Venda Geral"
                                        totalValue={indicatorsText.totalValue}
                                        averageValue={
                                            indicatorsText.totalAverageValue
                                        }
                                        averageProductQuantity={
                                            indicatorsText.totalAverageQuantity
                                        }
                                        productQuantity={
                                            indicatorsText.totalProductQuantity
                                        }
                                        orderQuantity={
                                            indicatorsText.totalOrderQuantity
                                        }
                                    />
                                ),
                                !storeProfile && (
                                    <SecondaryPanelCard
                                        cardTitle="Venda Física"
                                        totalValue={
                                            indicatorsText.physicalTotalValue
                                        }
                                        averageValue={
                                            indicatorsText.physicalAverageValue
                                        }
                                        averageProductQuantity={
                                            indicatorsText.physicalAverageQuantity
                                        }
                                        productQuantity={
                                            indicatorsText.physicalProductQuantity
                                        }
                                        orderQuantity={
                                            indicatorsText.physicalOrderQuantity
                                        }
                                    />
                                ),
                                <SecondaryPanelCard
                                    cardTitle="Venda Online"
                                    totalValue={indicatorsText.onlineTotalValue}
                                    averageValue={
                                        indicatorsText.onlineAverageValue
                                    }
                                    averageProductQuantity={
                                        indicatorsText.onlineAverageQuantity
                                    }
                                    productQuantity={
                                        indicatorsText.onlineProductQuantity
                                    }
                                    orderQuantity={
                                        indicatorsText.onlineOrderQuantity
                                    }
                                />,
                            ].filter((stepComponent, index) => {
                                if (
                                    dateRange.length > 1 ||
                                    (dateRange.length <= 1 && index !== 1)
                                ) {
                                    if (
                                        (index === 1 || index === 2) &&
                                        storeProfile
                                    ) {
                                        return null;
                                    }
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={`swipable-card-${index}`}>
                                            {Math.abs(activeStep - index) <= 2
                                                ? stepComponent
                                                : null}
                                        </div>
                                    );
                                }
                            })}
                        </SwipeableViews>

                        <Style.Stepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                        />
                    </Style.Root>
                    <Style.SecondaryDesktopRoot
                        primary={storeProfile}
                        container
                    >
                        <GetFirstPanelCard />

                        {!storeProfile && (
                            <>
                                <SecondaryPanelCard
                                    cardTitle="Venda Geral"
                                    totalValue={indicatorsText.totalValue}
                                    averageValue={
                                        indicatorsText.totalAverageValue
                                    }
                                    averageProductQuantity={
                                        indicatorsText.totalAverageQuantity
                                    }
                                    productQuantity={
                                        indicatorsText.totalProductQuantity
                                    }
                                    orderQuantity={
                                        indicatorsText.totalOrderQuantity
                                    }
                                />
                                <SecondaryPanelCard
                                    cardTitle="Venda Física"
                                    totalValue={
                                        indicatorsText.physicalTotalValue
                                    }
                                    averageValue={
                                        indicatorsText.physicalAverageValue
                                    }
                                    averageProductQuantity={
                                        indicatorsText.physicalAverageQuantity
                                    }
                                    productQuantity={
                                        indicatorsText.physicalProductQuantity
                                    }
                                    orderQuantity={
                                        indicatorsText.physicalOrderQuantity
                                    }
                                />
                            </>
                        )}
                        <SecondaryPanelCard
                            cardTitle="Venda Online"
                            totalValue={indicatorsText.onlineTotalValue}
                            averageValue={indicatorsText.onlineAverageValue}
                            averageProductQuantity={
                                indicatorsText.onlineAverageQuantity
                            }
                            productQuantity={
                                indicatorsText.onlineProductQuantity
                            }
                            orderQuantity={indicatorsText.onlineOrderQuantity}
                        />
                    </Style.SecondaryDesktopRoot>
                </>
            ) : (
                <LoadingComponent />
            )}
        </>
    );
}

export default SalesPersonPagePanel;
