import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/pt-br';

// Service import
import api from '~/services/api';

// Util import
import Snackbar from '~/util/SnackBar';
import { formatWithCent } from '~/util/format';
import { sortByFunction } from '~/util/sortByFunction';
import { handleRankingData } from '~/util/handleRankingData';
import {
    LOJA_MULTIMARCA,
    INFLUENCIADORES_OFF,
    TREINADOR_ADORO_CODIGO,
} from '~/util/perfilId';

// Component import
import StoreRankingPanel from '~/components/StoreRankingPanel';

function StoreRankingAffiliates() {
    const { initialDate, finalDate } = useSelector(state => state.status);
    const [initialRankingData, setInitialRankingData] = useState([]);
    const [rankingData, setRankingData] = useState([]);
    const [loadingRankingData, setLoadingRankingData] = useState(false);
    const [totalValue, setTotalValue] = useState('');
    const profile = useSelector(state => state.user.profile);

    const orderBy = 'money';
    const channelFilter = 'all';
    const listSorter = useMemo(() => sortByFunction(channelFilter, orderBy), [
        channelFilter,
        orderBy,
    ]);

    useEffect(() => {
        const getRankingData = async () => {
            try {
                if (initialDate && finalDate) {
                    setLoadingRankingData(true);

                    let rankingResponse;

                    switch (profile?.id_perfil) {
                        case LOJA_MULTIMARCA:
                            rankingResponse = await api.get(
                                '/store-manager/ranking-multimarca-salespeople',
                                {
                                    params: {
                                        dateInit: moment(initialDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        dateEnd: moment(finalDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        filialCode:
                                            profile?.users_branches[0]
                                                ?.codigo_filial,
                                        idAffiliates:
                                            profile?.users_affiliates[0]
                                                ?.id_affiliates,
                                    },
                                }
                            );
                            break;
                        case TREINADOR_ADORO_CODIGO:
                            rankingResponse = await api.get(
                                '/store-manager/ranking-adoro-codigo-salespeople',
                                {
                                    params: {
                                        dateInit: moment(initialDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        dateEnd: moment(finalDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        idAffiliates:
                                            profile?.users_affiliates[0]
                                                ?.id_affiliates,
                                        farmLatam: 'exclude',
                                    },
                                }
                            );
                            break;
                        case INFLUENCIADORES_OFF:
                            rankingResponse = await api.get(
                                '/store-manager/ranking-influencer-off-salespeople',
                                {
                                    params: {
                                        dateInit: moment(initialDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        dateEnd: moment(finalDate).format(
                                            'YYYY-MM-DD'
                                        ),
                                        filialCode:
                                            profile?.users_branches[0]
                                                ?.codigo_filial,
                                        idAffiliates:
                                            profile?.users_affiliates[0]
                                                ?.id_affiliates,

                                        includeExternalBranches: 'true',
                                    },
                                }
                            );
                            break;
                        default:
                            break;
                    }

                    const newTotalValue = rankingResponse.data.reduce(
                        (acc, curr) => acc + curr.value,
                        0
                    );
                    setTotalValue(formatWithCent(newTotalValue));

                    let newRankingData = handleRankingData(
                        rankingResponse.data
                    );

                    newRankingData.sort(listSorter);
                    newRankingData = newRankingData.map((data, index) => ({
                        ...data,
                        rankingPosition: index,
                    }));

                    setRankingData(newRankingData);
                    setInitialRankingData(newRankingData);
                }
            } catch (e) {
                setRankingData([]);
                console.log(e);
                Snackbar.warning('Falha ao buscar o ranking');
            } finally {
                setLoadingRankingData(false);
            }
        };
        getRankingData();
    }, [
        finalDate,
        initialDate,
        profile.users_affiliates,
        profile.users_branches,
        listSorter,
        profile.id_perfil,
    ]);

    return (
        <StoreRankingPanel
            loadingRankingData={loadingRankingData}
            rankingData={rankingData}
            orderBy={orderBy}
            channelFilter={channelFilter}
            totalValue={totalValue}
            initialRankingData={initialRankingData}
            setRankingData={setRankingData}
        />
    );
}

export default StoreRankingAffiliates;
