import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/pt-br';

// Component import
import StoreRankingPanel from '~/components/StoreRankingPanel';

// Service import
import api from '~/services/api';

// Util import
import Snackbar from '~/util/SnackBar';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import { shouldRemoveExternalProducts } from '~/util/functions';
import { sortByFunction } from '~/util/sortByFunction';
import { handleRankingData } from '~/util/handleRankingData';

function StoreRanking({ match }) {
    const { initialDate, finalDate, period } = useSelector(
        state => state.status
    );
    const [orderBy, setOrderBy] = useState('quota');
    const [orderByAsc, setOrderAsc] = useState('asc');
    const [rankingData, setRankingData] = useState([]);
    const [loadingRankingData, setLoadingRankingData] = useState(false);
    const [channelFilter, setChannelFilter] = useState('all');
    const userProfile = useSelector(state => state.user.profile);
    const [filialCode] = useState(match.params.filialCode);

    useEffect(() => {
        // não remover, condição para mostrar os valores corretos antes da implementação da lógica de vendas externas da off premium.
        const beforeExternalOffPremiumDateLimit = new Date('2024-09-27');

        const getRankingData = async () => {
            function filterByProfileId(el) {
                return el.id_perfil !== '99';
            }

            try {
                if (initialDate && finalDate) {
                    let dateInit = initialDate;
                    let dateEnd = finalDate;

                    const isTodayInCurrentAfferitionPeriod =
                        moment().isSameOrAfter(initialDate, 'day') &&
                        moment().isSameOrBefore(finalDate, 'day');

                    if (
                        period === 'month' &&
                        isTodayInCurrentAfferitionPeriod
                    ) {
                        const endOfMonth = moment(initialDate)
                            .add(1, 'month')
                            .endOf('month');
                        const { startDate, endDate } = getAssessmentDatesByDate(
                            endOfMonth,
                            userProfile?.users_brands?.map(
                                userBrandObj => userBrandObj.id_marca
                            )[0]
                        );
                        dateInit = startDate;
                        dateEnd = endDate;
                    }

                    setLoadingRankingData(true);

                    const params = {
                        dateInit: moment(dateInit).format('YYYY-MM-DD'),
                        dateEnd: moment(dateEnd).format('YYYY-MM-DD'),
                        filialCode,
                        filialBrandId: userProfile.marcaFilial,
                        removeExternalProducts: shouldRemoveExternalProducts(
                            userProfile.marcaFilial
                        ),
                    };

                    if ([2].includes(userProfile.marcaFilial)) {
                        params.farmLatam = 'exclude';
                    }

                    if (
                        moment(initialDate).format('YYYY-MM-DD') <=
                        moment(beforeExternalOffPremiumDateLimit).format(
                            'YYYY-MM-DD'
                        )
                    ) {
                        delete params.removeExternalProducts;
                        params.offExternal = 'exclude';
                    }

                    const rankingResponse = await api.get(
                        '/store-manager/ranking-salespeople',
                        {
                            params,
                        }
                    );

                    const filteredRankingResponse = rankingResponse?.data?.filter(
                        filterByProfileId
                    );

                    const newRankingData = handleRankingData(
                        filteredRankingResponse
                    );

                    newRankingData.sort(sortByFunction(channelFilter, orderBy));

                    setRankingData(newRankingData);
                }
            } catch (e) {
                setRankingData([]);
                console.log(e);
                Snackbar.warning('Falha ao buscar o ranking');
            } finally {
                setLoadingRankingData(false);
            }
        };
        getRankingData();
    }, [
        initialDate,
        finalDate,
        filialCode,
        period,
        userProfile.marcaFilial,
        userProfile.users_branches,
        userProfile.users_brands,
        channelFilter,
        orderBy,
    ]);

    return (
        <StoreRankingPanel
            loadingRankingData={loadingRankingData}
            rankingData={rankingData}
            setRankingData={setRankingData}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderByAsc={orderByAsc}
            setOrderAsc={setOrderAsc}
            channelFilter={channelFilter}
            setChannelFilter={setChannelFilter}
        />
    );
}

export default StoreRanking;
