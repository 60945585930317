import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Box, Grid } from '@mui/material';

import api from '~/services/api';

import LoadingComponent from './LoadingComponent';

import Header from '~/components/Header';
import OrderListPanel from '~/components/OrderListPanel';
import DateRangePicker from '~/components/DateRangePicker';
import ChallengePagePanel from '~/components/SalesPersonPanelPiloto/ChallengePagePanel';

import { signOut } from '../../store/modules/auth/actions';
import {
    changeFinalDate,
    changeInitialDate,
    changeDatePeriod,
} from '~/store/modules/status/actions';

import Snackbar from '~/util/SnackBar';
import { FARM } from '~/util/brands';
import { TREINADOR_ADOROCODIGO } from '~/util/perfilId';
import FarmNewsModal from '~/components/FarmNewsModal';

import * as Style from './styles';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';

function AdoroCodigoChallenge() {
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [challengeData, setChallengeData] = useState([]);
    const [aggregatedOrdersIndicators, setAggregatedOrdersInicators] = useState(
        {}
    );
    const [panelLoaded, setPanelLoaded] = useState(true);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate, isFaturado, period } = useSelector(
        state => state.status
    );

    useEffect(() => {
        const modalAlreadySeen = localStorage.getItem('FarmNewsModal');

        if (modalAlreadySeen === 'seen') {
            return;
        }

        if (userProfile?.id_perfil === TREINADOR_ADOROCODIGO) {
            setOpen(true);
        }
    }, [userProfile.id_perfil]);

    const handleFarmNewsModalClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setPanelLoaded(false);
        const getAggregatedCapturedOrders = async () => {
            try {
                const requestParams = {
                    fatured: 'false',
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    salesCode: userProfile.users_sales_code.map(
                        salesCodeObj => salesCodeObj.codigo_vendedor
                    ),
                    transaction: 'true',
                    brands: [FARM],
                };

                const response = await api.get(
                    `/salesperson/aggregated-orders`,
                    {
                        params: requestParams,
                    }
                );

                setAggregatedOrdersInicators(
                    response.data.data.aggregatedByBrand[FARM]
                );
            } catch (err) {
                Snackbar.error(
                    'Erro retornando os indicadores de pedidos capturados'
                );
            }
        };

        const getChallengeData = async () => {
            try {
                const response = await api.get(
                    'salesperson/adoro-codigo-challenge',
                    {
                        params: {
                            startDate: moment(initialDate).format('YYYY-MM-DD'),
                            endDate: moment(finalDate).format('YYYY-MM-DD'),
                            salesCode:
                                userProfile.users_sales_code[0].codigo_vendedor,
                        },
                    }
                );

                setChallengeData(response.data);
            } catch (err) {
                Snackbar.error('Erro retornando dados do desafio');
            }
        };

        const getOrders = async () => {
            setOrdersLoaded(false);
            try {
                const requestParams = {
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    salesCode: userProfile.users_sales_code.map(
                        salesCodeObj => salesCodeObj.codigo_vendedor
                    ),
                    orderType: isFaturado ? 'invoiced' : 'captured',
                    brands: [2],
                    transaction: 'true',
                };

                const response = await api.get(`salesperson/orders`, {
                    params: requestParams,
                });

                const formattedOrders = await handleOrdersResponse(
                    response.data.data,
                    false
                );

                setOrders(formattedOrders);
            } catch (err) {
                Snackbar.error('Erro retornando lista de pedidos');
            }
            setOrdersLoaded(true);
        };

        if (period === 'regular_month') {
            Promise.all([
                getAggregatedCapturedOrders(),
                getChallengeData(),
                getOrders(),
            ])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        dispatch(signOut());
                    }
                })
                .finally(() => setPanelLoaded(true));
        }
    }, [
        initialDate,
        finalDate,
        isFaturado,
        userProfile.users_sales_code,
        dispatch,
        period,
    ]);

    useEffect(() => {
        dispatch(changeDatePeriod('regular_month'));
        const endOfMonth = moment().endOf('month');
        const startOfMonth = moment().startOf('month');

        dispatch(changeInitialDate(startOfMonth));
        dispatch(changeFinalDate(endOfMonth));
    }, [dispatch]);

    return (
        <>
            <Header />
            <Style.DatePickerContainer>
                <Box style={{ width: 'fit-content' }}>
                    <DateRangePicker fixedPeriod />
                </Box>
            </Style.DatePickerContainer>
            {!panelLoaded && <LoadingComponent />}
            <Grid container justifyContent="space-evenly">
                <Grid item xs={12}>
                    <ChallengePagePanel
                        panelLoaded={panelLoaded}
                        challengeData={challengeData}
                        totalSalesValue={aggregatedOrdersIndicators.value}
                    />
                </Grid>
                <Style.LateralPanelContainer item xs={12}>
                    <OrderListPanel
                        ordersLoaded={ordersLoaded}
                        orders={orders}
                    />
                </Style.LateralPanelContainer>
            </Grid>
            <FarmNewsModal
                onClose={handleFarmNewsModalClose}
                open={open}
                fullView={false}
                title={'Novo desafio da Farm!'}
            >
                <Style.MainTypography>
                    Agora você faz parte do desafio Farm!
                </Style.MainTypography>
                <Style.MainTypography>
                    Acompanhe seu percentual de atingimento diariamente no Mais
                    Vendas para que consiga alcançar o grande objetivo final,
                    100% no fim da competência!
                </Style.MainTypography>
            </FarmNewsModal>
        </>
    );
}

export default AdoroCodigoChallenge;
