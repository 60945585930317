import React from 'react';
import Close from '@mui/icons-material/Close';

import modalImage from '~/assets/modal_farm_image.png';

import * as Style from './styles';

function FarmNewsModal({ open, onClose, children, fullView, title }) {
    const handleOnClose = () => {
        localStorage.setItem('FarmNewsModal', 'seen');
        if (onClose) {
            onClose();
        }
    };

    return (
        <Style.DialogContainer
            open={open}
            onClose={handleOnClose}
            disableScrollLock
            primary={fullView}
        >
            <Style.CloseButtonContainer onClick={handleOnClose}>
                <Close />
            </Style.CloseButtonContainer>
            <Style.ModalImage
                component="img"
                src={modalImage}
                title="Grupo Soma"
                primary={fullView}
            />
            <Style.ModalTitle>{title}</Style.ModalTitle>
            {children}
        </Style.DialogContainer>
    );
}

export default FarmNewsModal;
